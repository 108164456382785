/**
 * @description 时间过滤器
 */
const dateTimeFormat = (date: any, format: any) => {
    //时间格式化 <p>{{$filter.dateTimeFormat(new Date(),"yyyy-MM-dd hh:mm")}}</p>
    if(!date){
        return '-'
    }
    const getDate = (date: any) => {
        if (typeof date === "string") {
            var mts = date.match(/(\/Date\((\d+)\)\/)/);
            if (mts && mts.length >= 3) {
                date = parseInt(mts[2]);
            }
        }
        date = new Date(date);
        return date;
    };

    if (typeof date == "string") {
        date = date.replace(/-/g, "/").replace("T", " ");
    }
    date = getDate(date);
    if (!date || date.toUTCString() == "Invalid Date") {
        return "";
    }
    var map: any = {
        "M": date.getMonth() + 1,                       //月份
        "d": date.getDate(),                            //日
        "h": date.getHours(),                           //小时
        "m": date.getMinutes(),                         //分
        "s": date.getSeconds(),                         //秒
        "q": Math.floor((date.getMonth() + 3) / 3),     //季度
        "S": date.getMilliseconds()                     //毫秒
    };
    format = format.replace(/([yMdhmsqS])+/g, function (all: any, t: any) {
        var v: any = map[t];

        if (v !== undefined) {
            if (all.length > 1) {
                v = '0' + v;
                v = v.substr(v.length - 2);
            }
            return v;
        } else if (t === 'y') {
            return (date.getFullYear() + '').substr(4 - all.length);
        }
        return all;
    });
    return format;

}
/**
 * @description 时间过滤器-获取当前月份最后一天
 */
const getYearIn = (date: any) => {
    var currentMonth: any = date.getMonth();
    var nextMonth: any = ++currentMonth;
    var nextMonthFirstDay: any = new Date(date.getFullYear(), nextMonth, 1);
    var oneDay: any = 1000 * 60 * 60 * 24;
    var lastTime: any = new Date(nextMonthFirstDay - oneDay);
    var endMonth: any = parseInt(lastTime.getMonth() + 1);
    var endDay: any = lastTime.getDate();
    if (endMonth < 10) {
        endMonth = '0' + endMonth
    }
    if (endDay < 10) {
        endDay = '0' + endDay
    }
    return date.getFullYear() + '-' + endMonth + '-' + endDay
}
const nameTimeFormat = (value:any) =>{
    value = dateTimeFormat(value,'yyyy-MM-dd')
    let present = dateTimeFormat(new Date(),'yyyy-MM-dd')
    let name = ""
    if(value == present){
        name = "今天"
    }else{
        var weekDay = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];  
        var myDate = new Date(Date.parse(value));  
        name = weekDay[myDate.getDay()]; 
    }
    return name
}
const handleMoney = (num:any) => {
    if(!num){return {num: 0, unit: "元"};}
    // 首先先声明一个金额单位数组
    let AmountUnitlist = ["元", "万", "亿", "兆", '京', '垓', '杼']
    // 将数字金额转为字符串
    let strnum = num.toString()
    // 声明一个变量用于接收金额单位
    let AmountUnit = ''
    // 循环遍历单位数组
    AmountUnitlist.find((item, index) => {
        let newNum:any = ''
        // 判断一下传进来的金额是否包含小数点
        if (strnum.indexOf('.') !== -1) {
            // 若有则将小数点前的字符截取出来
            newNum = strnum.substring(0, strnum.indexOf('.'))
        } else {
            // 没有则直接等于原金额
            newNum = strnum
        }
        // 判断一下经过小数点截取后的金额字符长度是否小于5
        if (newNum.length < 5) {
            // 若小于5则接收当前单位，并跳出迭代
            AmountUnit = item
            return true
        } else {
            // 若不小于5则将经过小数点截取处理过后的字符除以10000后作为下一轮迭代的初始金额重新判断(每一个单位之间相距4位数，故除以10000)
            strnum = (newNum * 1 / 10000).toString()
        }
    })
    let money:any = {num: 0, unit: ""}
    // 保留2位小数
    money.num = (strnum * 1).toFixed(2)
    // 接收单位
    money.unit = AmountUnit
    return money
}
export { dateTimeFormat, getYearIn, handleMoney, nameTimeFormat };
